import Config from '../Config';
import LocalData from '../helpers/local-data';

let localPropertyData = LocalData.get(Config.LOCAL_STORAGE_TYPES.SET_PROPERTY);
let localArticlesData = LocalData.get(Config.LOCAL_STORAGE_TYPES.SET_ARTICLES);

const initialState = {
    type: null,

    property: localPropertyData ? JSON.parse(localPropertyData) : null,
    articles: localArticlesData ? JSON.parse(localArticlesData) : null,
    loading: true
};

const Reducer = (state = initialState, action) => {
    switch (action.type) {
        case Config.REDUX_REDUCER_TYPES.SET_PROPERTY:

            return {
                ...state,
                ...{type: action.type, property: action.payload}
            };
        case Config.REDUX_REDUCER_TYPES.SET_ARTICLES:

            return {
                ...state,
                ...{type: action.type, articles: action.payload}
            };
        case Config.REDUX_REDUCER_TYPES.SET_LOADING:

            return {
                ...state,
                ...{type: action.type, loading: action.payload}
            };
        default:
            return state;
    }
};

export default Reducer;
