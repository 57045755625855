import Services from './services';
import {store} from '../redux/store';
import Config from '../Config';

const bookmarkModel = {
    remote: (property_id) => {
        return Services.getApi(
            Services.basePath() + `/content/${property_id}`
        );
    },
    findOne: article_id => {
        let articles = store.getState().Reducer.articles;

        return articles.find(x => parseInt(x.id) === parseInt(article_id));
    },
    deleteOne: (article_id, article_ids, property_id) =>{
        article_ids = article_ids.split(',').filter(x => parseInt(x) !== parseInt(article_id));

        window.location.href = `/property/${property_id}/articles/${article_ids.join(',')}`;
    }
};

export default bookmarkModel;
