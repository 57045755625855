import axios from 'axios';

class Services {
    static basePath() {
        return process.env.REACT_APP_BACKEND_APP_URL + process.env.REACT_APP_BACKEND_APP_SERVICE_PREFIX;
    }

    static pmsIntegrationBasePath(hasPrefix = true) {
        return hasPrefix
            ? process.env.REACT_APP_PMS_INTEGRATION_APP_URL + process.env.REACT_APP_PMS_INTEGRATION_APP_SERVICE_PREFIX
            : process.env.REACT_APP_PMS_INTEGRATION_APP_URL;
    }

    static checkForUnauthorisedAccess(error) {
        // if (error && error.response && error.response.status === 401) {
        //     Auth.resetAuth();
        //     window.location.replace('/#' + Config.URLs.login);
        // }
    }

    static wrapError(error) {
        if (error && error.response && error.response.status) {
            return error;
        } else {
            return {
                response: {
                    code: 500,
                    message:
                        error && error.message
                            ? error.message
                            : 'An error has occurred, please try again later.'
                }
            };
        }
    }

    // static checkForAccessTokenOrGenerate() {
    //     return new Promise((resolve, reject) => {
    //         HCNLocalData.load(Config.LOCAL_DATA_KEYS.ACCESS_TOKEN).then(
    //             access_token => {
    //                 if (!access_token) {
    //                     axios
    //                         .post(
    //                             Config.getAuthBasePath() + '/oauth/token',
    //                             {
    //                                 grant_type: 'client_credentials',
    //                             },
    //                             {
    //                                 headers: {
    //                                     'Content-Type': 'application/json',
    //                                 },
    //                                 auth: {
    //                                     username:
    //                                         Config.getAccessTokenUsername(),
    //                                     password:
    //                                         Config.getAccessTokenPassword(),
    //                                 },
    //                             },
    //                         )
    //                         .then(function (response) {
    //                             if ([200, 201].includes(response.status)) {
    //                                 HCNLocalData.save(
    //                                     Config.LOCAL_DATA_KEYS.ACCESS_TOKEN,
    //                                     response.data.access_token,
    //                                 );
    //                                 resolve(response.data.access_token);
    //                             } else {
    //                                 reject(response.data);
    //                             }
    //                         })
    //                         .catch(function (error) {
    //                             console.log(error);
    //                         });
    //                 } else {
    //                     resolve(access_token);
    //                 }
    //             },
    //         );
    //     });
    // }

    static async getDefaultHeaders(useBasicAuth = false) {
        // let token = store.getState().Reducer.token;
        // if (!token) {
        //     token = await Services.checkForAccessTokenOrGenerate();
        // }

        return {
            'Content-Type': 'application/json'
            // Authorization: !useBasicAuth
            //     ? 'Bearer ' + token
            //     : `Basic ${PMS_INTEGRATION_APP_BASIC_AUTH}`,
        };
    }

    static async getApi(apiUrl) {
        let headers = await Services.getDefaultHeaders();

        return new Promise((resolve, reject) => {
            axios
                .get(apiUrl, {
                    headers: headers
                })
                .then(function (response) {
                    if (
                        response.data.error === false &&
                        [200, 201].includes(response.data.code)
                    ) {
                        resolve(response.data.data);
                    } else {
                        reject(response.data.message);
                    }
                })
                .catch(function (error) {
                    Services.checkForUnauthorisedAccess(error);
                    reject(
                        Services.wrapError(error).response.data
                            ? Services.wrapError(error).response.data.message
                            : Services.wrapError(error).response.message
                    );
                });
        });
    }
}

export default Services;
