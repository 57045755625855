import React, {useEffect, useState} from 'react';
import './header.css';
import propertyModel from '../../data/property-model';
import {useHistory} from 'react-router-dom';
import {store} from '../../redux/store';
import Config from '../../Config';

const Header = (props) => {
    const [property, setProperty] = useState(store.getState().Reducer.property);
    const history = useHistory();

    useEffect(() => {
        const unsubscribeRedux = store.subscribe(() => {
            let state = store.getState();

            if (state.Reducer.type === Config.REDUX_REDUCER_TYPES.SET_PROPERTY) {
                setProperty(state.Reducer.property);
            }
        });

        return () => {
            unsubscribeRedux();
        };
    }, []);

    if (property) {
        return (
            <div className="header">
                <div className="menu_icon">
                    {/*<img src="/icons/menu_icon.png" alt="Menu" />*/}
                </div>
                <div className="property_icon">
                    <img src={property.logo} onClick={() => history.push(`/property/${props.match.params.propertyId}/articles/${props.match.params.articleIds}`)} alt="Logo"/>
                </div>
                <div className="bookmark_icon">
                    {/*<img src="/icons/bookmark_icon.png" alt="Menu" />*/}
                </div>
            </div>
        );
    } else {
        return <div />;
    }
};

export default Header;
