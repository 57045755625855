const HCNArray = {
    addItemIfNotExistsOrUpdate: (arr, item, sort, sort_order) => {
        arr = arr !== null ? arr : [];

        if (item) {
            let existing_item_index = arr.findIndex(x => x.id === item.id);

            if (existing_item_index >= 0) {
                arr[existing_item_index] = item;
            } else {
                arr.push(item);
                arr = arr.sort((a, b) =>
                    a[sort] > b[sort]
                        ? sort_order === 'desc'
                        : sort_order !== 'desc'
                );
            }
        }

        return arr;
    },
    uniqueArrayBasedOnField: (arr, field) => {
        let newArray = [];
        arr.map(item => {
            if (!newArray.find(x => x[field] === item[field])) {
                newArray.push(item);
            }
        });

        return newArray;
    },
    haveAtLeastOneMatchingItem: (arr1, arr2, field) => {
        return (
            arr1.filter(x =>
                arr2.find(
                    y => (field ? y[field] : y) === (field ? x[field] : x)
                )
            ).length > 0
        );
    }
};

export default HCNArray;
