import Services from './services';

const contentModel = {
    remote: (property_id) => {
        return Services.getApi(
            Services.basePath() + `/content/${property_id}`
        );
    },
    // findOne: parent_id => {
    //     const promise = new Promise((resolve, reject) => {
    //         const db = new PouchDB(LOCAL_DATABASE_NAME_CONTENT);
    //         db.get(parent_id)
    //             .then(doc => {
    //                 resolve(doc);
    //             })
    //             .catch(err => {
    //                 reject(err);
    //             });
    //     });
    //
    //     return promise;
    // },
    subscribe: (state_param, view_name, callback) => {
        // return subscribe(
        //     state_param,
        //     view_name,
        //     'content_model',
        //     LOCAL_DATABASE_NAME_CONTENT,
        //     callback,
        // );
    }
};

export default contentModel;
