const Config = {
    REDUX_REDUCER_TYPES: {
        SET_PROPERTY: 'SET_PROPERTY',
        SET_ARTICLES: 'SET_ARTICLES',
        SET_LOADING: 'SET_LOADING'
    },
    LOCAL_STORAGE_TYPES: {
        SET_PROPERTY: 'SET_PROPERTY',
        SET_ARTICLES: 'SET_ARTICLES'
    }
};

export default Config;
