import propertyModel from './property-model';
import Config from '../Config';
import contentModel from './content-model';
import HCNArray from '../helpers/hcn-array';

const loadRemoteData = async (dispatch, article_ids, property_id) => {
    await propertyModel
        .remote(property_id)
        .then(async property_info => {
            dispatch({
                type: Config.REDUX_REDUCER_TYPES.SET_PROPERTY,
                payload: property_info
            });
        })
        .catch(err => {
            console.log('err1', err);
        });

    if (article_ids && article_ids.length > 0) {
        await contentModel
            .remote(property_id)
            .then(async content => {
                let articles = content.articles;
                articles = articles.filter(art => article_ids.find(x => parseInt(art.id) === parseInt(x)));
                articles = articles.filter(art => art.published_at !== null);
                articles = HCNArray.uniqueArrayBasedOnField(articles, 'id');

                dispatch({
                    type: Config.REDUX_REDUCER_TYPES.SET_ARTICLES,
                    payload: articles
                });
            })
            .catch(err => {
                console.log('err2', err);
            });
    }
};

export default loadRemoteData;
