import React, {useEffect, useState} from 'react';
import './article.css';
import Header from '../components/header/header';
import bookmarkModel from '../data/bookmark-model';
import ReactMarkdown from 'react-markdown';
import LocalData from '../helpers/local-data';
import Config from '../Config';
import loadRemoteData from '../data/load-remote-data';
import {store} from '../redux/store';
import {useDispatch} from 'react-redux';

const Article = (props) => {
    const [article, setArticle] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        loadRemoteData(dispatch, [props.match.params.articleId], props.match.params.propertyId).then(() => {
            dispatch({
                type: Config.REDUX_REDUCER_TYPES.SET_LOADING,
                payload: false
            });

            setArticle(bookmarkModel.findOne(props.match.params.articleId));
        });
    }, []);

    useEffect(() => {
        article && LocalData.set(Config.LOCAL_STORAGE_TYPES.SET_ARTICLES, JSON.stringify(article));
    }, [article]);

    if (article) {
        return (
            <div className="container article">
                <Header {...props} />
                <div className="body_container">
                    <div className="img_container">
                        <img className="img" src={article.images[0].image} alt={article.title} />
                        <div className="img_gradient"></div>
                    </div>
                    <div className="description_area">
                        <div className="title">{article.title}</div>
                        <div className="subtitle">{article.address}</div>
                        <div className="description">
                            <ReactMarkdown>{article.description}</ReactMarkdown>
                        </div>
                        <hr className="horizontal_line"/>
                    </div>
                </div>
                <div className="footer">
                    <div className="bookmarked_area">
                        <div className="bookmark_bottom_icon">
                            <img className="bookmark_bottom_icon_img" src="/icons/bookmarked_icon.png" alt="Menu"/>
                        </div>
                        <div className="bookmark_bottom_text">Bookmarked</div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <div />;
    }
};

export default Article;
