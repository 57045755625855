import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './App.css';
import Loader from './components/loader/loader';
import {store} from './redux/store';
import {Provider} from 'react-redux';
import Config from './Config';
import Layout from './components/layout';
import MyBookmarks from './views/my-bookmarks';
import Article from './views/article';

const fallback =
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse">&nbsp;</div>
    </div>
;

function App() {
    const [loading, setLoading] = useState(store.getState().Reducer.loading);

    useEffect(() => {
        const unsubscribeRedux = store.subscribe(() => {
            let state = store.getState();

            if (state.Reducer.type === Config.REDUX_REDUCER_TYPES.SET_LOADING) {
                setLoading(state.Reducer.loading);
            }
        });

        return () => {
            unsubscribeRedux();
        };

    }, []);

    return (
        <div>
            <Provider store={store}>
                <Router>
                    <React.Suspense fallback={fallback}>
                        {loading &&
                            <Loader loading={<Loader/>}/>
                        }
                        <Switch>
                            <Route path="/property/:propertyId/articles/:articleIds" name="My Bookmarks" exact={true} render={props => <MyBookmarks {...props}/>}/>
                            <Route path="/property/:propertyId/articles/:articleIds/article/:articleId" name="Article" exact={true} render={props => <Article {...props}/>}/>
                        </Switch>
                    </React.Suspense>
                </Router>
            </Provider>
        </div>
    );
}

export default App;
