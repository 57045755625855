import React, {useEffect, useState} from 'react';
import './my-bookmarks.css';
import Header from '../components/header/header';
import bookmarkModel from '../data/bookmark-model';
import {store} from '../redux/store';
import Config from '../Config';
import {useDispatch} from 'react-redux';
import loadRemoteData from '../data/load-remote-data';

const MyBookmarks = props => {
    const [bookmarks, setBookmarks] = useState(store.getState().Reducer.articles);

    const dispatch = useDispatch();

    useEffect(() => {
        loadRemoteData(dispatch, props.match.params.articleIds.split(','), props.match.params.propertyId).then(() => {
            dispatch({
                type: Config.REDUX_REDUCER_TYPES.SET_LOADING,
                payload: false
            });
        });

        const unsubscribeRedux = store.subscribe(() => {
            let state = store.getState();

            if (state.Reducer.type === Config.REDUX_REDUCER_TYPES.SET_ARTICLES) {
                setBookmarks(state.Reducer.articles);
            }
        });

        return () => {
            unsubscribeRedux();
        };
    }, []);

    const deleteBookmark = (e, article_id, article_ids, property_id) => {
        e.stopPropagation();
        e.preventDefault();
        e.nativeEvent.stopImmediatePropagation();

        bookmarkModel.deleteOne(article_id, article_ids, property_id);
    };

    return (
        <div className="container my_bookmarks">
            <Header {...props} />
            <div className="body_container">
                <div className="add_bookmark_icon_text">
                    <img src="/icons/add_bookmark_icon.png" alt="Menu"/>
                    <h1 className="title">My Bookmarks</h1>
                </div>
                <div className="carousel_container">
                    {bookmarks && bookmarks.length > 0 && bookmarks.map((article, index) =>
                        <div key={index} className="carousel_item" onClick={()=> {
                            window.location.href = `/property/${props.match.params.propertyId}/articles/${props.match.params.articleIds}/article/${article.id}`;
                        }}>
                            <div className="carousel_img_container">
                                <div className="carousel_img" style={{backgroundImage: `url('${article.images[0].image}')`}} />
                                <div className="carousel_img_gradient"></div>
                            </div>
                            <div className="text_container">
                                <div className="close_text_container">
                                    <a href={'#'} onClick={(e) => deleteBookmark(e, article.id, props.match.params.articleIds, props.match.params.propertyId)}>
                                        <div className="close_text">X</div>
                                    </a>
                                </div>
                                <div className="carousel_title_container">
                                    <div className="carousel_title">{article.title}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="footer">

            </div>
        </div>
    );
};

export default MyBookmarks;
