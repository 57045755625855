import Services from './services';

const propertyModel = {
    remote: (property_id) => {
        return Services.getApi(
            Services.basePath() + `/properties/${property_id}`
        );
    }
    // findOne: (property_id) => {
    //     const db = new PouchDB(process.env.REACT_APP_LOCAL_DATABASE_NAME_PROPERTY);
    //     return db.get(process.env.REACT_APP_PROPERTY_ID);
    // }
    // subscribe: (state_param, view_name, callback) => {
    //     return subscribe(
    //         state_param,
    //         view_name,
    //         'get_property_info',
    //         LOCAL_DATABASE_NAME_PROPERTY,
    //         callback,
    //     );
    // },
};

export default propertyModel;
